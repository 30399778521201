import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import Header from "../components/header";
import Seo from "../components/seo";

import icon_computer from "../images/general/icon-computer.svg";

const Contact = () => (
  <Layout>
    <Seo title="Contacto" />
    <Header />
    <main className="contact">
      <div className="description">
        <h1>Contacto</h1>
        <p>
          Si necesitas ponerte en contacto con nosotros puedes hacerlo a través
          de cualquiera de los siguientes canales:
        </p>
        <div className="contact-containter">
          <div className="contact-info">
            <h2>Localización</h2>
            <StaticQuery
              query={graphql`
                {
                  allLocationsJson {
                    edges {
                      node {
                        locations {
                          faxTransfer
                          fax
                          direction
                          phone
                          province
                          city
                          zip
                        }
                      }
                    }
                  }
                }
              `}
              render={(data) =>
                data.allLocationsJson.edges[0].node.locations.map(
                  (value, index) => (
                    <div key={index}>
                      <h3>{value.province}</h3>
                      <p>
                        {value.direction}
                        <br />
                        {`${value.zip} ${value.city}`}
                        <br />
                        {value.province}
                      </p>
                      <p className="label">
                        <small>Teléfono</small>
                      </p>
                      <a href={`tel:${value.phone}`}>{value.phone}</a>

                      {value.faxTransfer ? (
                        <p className="label">
                          <small>Fax</small>
                          <br />
                          <a href={`tel:${value.fax}`}>{value.fax}</a>
                        </p>
                      ) : (
                        ""
                      )}

                      {value.faxTransfer ? (
                        <p className="label">
                          <small>Fax transfer</small>
                          <br />
                          <a href={`tel:${value.faxTransfer}`}>
                            {value.faxTransfer}
                          </a>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                )
              }
            />
          </div>
          <div className="contact-form">
            <h2>
              Contacta con nosotros{" "}
              <a
                href="https://cloud.cenfarte.es/index.php/s/5wBG5piBWneiAKA/download"
                title="Descargar programa de gestión remota"
                className="icon_support"
              >
                <img src={icon_computer} alt="Icono de un ordenador" />
              </a>
            </h2>
            <form action="https://formspree.io/f/mleozvqw" method="POST">
              <p>Los campos con asterisco (*) son obligatorios.</p>
              <p>
                <label htmlFor="name">
                  <strong>
                    <abbr title="Campo obligatorio">*</abbr>
                  </strong>
                  <span> Nombre y apellidos: </span>
                </label>
                <br />
                <input type="text" id="name" name="username" required />
              </p>
              <p>
                <label htmlFor="phone">
                  <span> Teléfono: </span>
                </label>
                <br />
                <input type="phone" id="phone" name="phone" />
              </p>{" "}
              <p>
                <label htmlFor="email">
                  <strong>
                    <abbr title="Campo obligatorio">*</abbr>
                  </strong>
                  <span> Correo electrónico: </span>
                </label>
                <br />
                <input type="email" id="name" name="email" required />
              </p>{" "}
              <p>
                <label htmlFor="comentarios">
                  <strong>
                    <abbr title="Campo obligatorio">*</abbr>
                  </strong>
                  <span> Comentarios: </span>
                </label>
                <br />
                <textarea id="comentarios" name="comments" />
              </p>
              <label
                htmlFor="politica"
                style={{
                  display: `flex`,
                  margin: `15px 10px 0 0`,
                  cursor: "pointer",
                }}
              >
                <input
                  type="checkbox"
                  required
                  style={{ width: `auto`, margin: `14px 10px -10px 0` }}
                  id="politica"
                />
                <p style={{ fontSize: 15 }}>Acepto las condiciones de uso</p>
              </label>
              <p style={{ fontSize: 13, lineHeight: "17px" }}>
                A efectos de la Ley Orgánica 15/1999, de Protección de datos de
                carácter personal, te informamos que tus datos serán incluidos
                en un fichero de carácter personal cuyo responsable es Cenfarte
                y que autoriza su uso por las unidades competentes del mismo.
                Como usuario podrás en todo momento ejercitar los derechos de
                acceso, rectificación, cancelación y en su caso revocación del
                consentimiento para la cesión de tus datos
              </p>
              <input
                type="hidden"
                name="_next"
                value="https://www.cenfarte.es/confirmacion"
              />
              <button type="submit" className="btn-regular">
                Enviar
              </button>{" "}
            </form>
          </div>
          <hr />

          <h2>Buzón de sugerencias</h2>
          <div className="suggestions-form">
            <form action="https://formspree.io/dpd@cenfarte.es" method="POST">
              <p>Los campos con asterisco (*) son obligatorios.</p>
              <p>
                <label htmlFor="name">
                  <strong>
                    <abbr title="Campo obligatorio">*</abbr>
                  </strong>
                  <span> Nombre y apellidos: </span>
                </label>
                <br />
                <input type="text" id="name" name="username" required />
              </p>
              <p>
                <label htmlFor="phone">
                  <span> Teléfono: </span>
                </label>
                <br />
                <input type="phone" id="phone" name="phone" />
              </p>{" "}
              <p>
                <label htmlFor="email">
                  <strong>
                    <abbr title="Campo obligatorio">*</abbr>
                  </strong>
                  <span> Correo electrónico: </span>
                </label>
                <br />
                <input type="email" id="name" name="email" required />
              </p>{" "}
              <p>
                <label htmlFor="comentarios">
                  <strong>
                    <abbr title="Campo obligatorio">*</abbr>
                  </strong>
                  <span> Comentarios: </span>
                </label>
                <br />
                <textarea id="comentarios" name="comments" />
              </p>
              <label
                htmlFor="politica_sugerencias"
                style={{
                  display: `flex`,
                  margin: `15px 10px 0 0`,
                  cursor: "pointer",
                }}
              >
                <input
                  type="checkbox"
                  style={{ width: `auto`, margin: `14px 10px -10px 0` }}
                  id="politica_sugerencias"
                  required
                />
                <p style={{ fontSize: 15 }}>Acepto las condiciones de uso</p>
              </label>
              <p style={{ fontSize: 13, lineHeight: "17px" }}>
                A efectos de la Ley Orgánica 15/1999, de Protección de datos de
                carácter personal, te informamos que tus datos serán incluidos
                en un fichero de carácter personal cuyo responsable es Cenfarte
                y que autoriza su uso por las unidades competentes del mismo.
                Como usuario podrás en todo momento ejercitar los derechos de
                acceso, rectificación, cancelación y en su caso revocación del
                consentimiento para la cesión de tus datos
              </p>
              <input
                type="hidden"
                name="_next"
                value="https://www.cenfarte.es/confirmacion"
              />
              <button type="submit" className="btn-regular">
                Enviar sugerencia
              </button>{" "}
            </form>
          </div>
        </div>
      </div>
    </main>
  </Layout>
);

export default Contact;
